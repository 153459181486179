<template>
  <div class="login-container">
    <div class="login-footer">
      <p style="font-weight: bold;">你的工作正在帮助美迈科技成为一家伟大的公司，谢谢你！</p>
      <p>Your hardwork is helping Miracle Miles Tech. be a great company. Thank you!</p>
    </div>
    <div id="content">
      <img src="../../assets/img/logo.png" alt="logo">
      <div class="lang-set">
        <span class="mr-4" @click="handleSetLanguage('zh')">中文</span>
        <span class="mr-4" @click="handleSetLanguage('en')">English</span>
      </div>
      <!-- <lang-select class="set-language" /> -->
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="off" label-position="left">

        <div class="title-container">
          <h3 class="title">
            <!-- {{ $t('login.title') }} -->
          </h3>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            autocomplete="off"
            :placeholder="$t('login.username')"
            name="username"
            type="text"
            tabindex="1"
          />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              autocomplete="off"
              :type="passwordType"
              :placeholder="$t('login.password')"
              name="password"
              tabindex="2"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
        </el-tooltip>
        <div class="code-verify">
          <el-form-item prop="validCode" class="flex">
            <span class="svg-container">
              <svg-icon icon-class="verificationCode" />
            </span>
            <el-input
              ref="verificationCode"
              v-model="loginForm.validCode"
              size="mini"
              :placeholder="$t('login.verificationCode')"
              name="verificationCode"
              type="text"
              tabindex="3"
              autocomplete="on"
              @keydown.enter.native="handleLogin"
            />
          </el-form-item>
          <img class="mr-4" :src="codeUrl" alt="" @click="getCodeUrl">
        </div>
        <el-button :loading="loading" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">
          {{ $t('login.logIn') }}
        </el-button>
      </el-form>
    </div>

  </div>
</template>

<script>
import { validUsername } from '@/utils/validate'
import { createUniqueString } from '@/utils'
// import LangSelect from '@/components/LangSelect'

export default {
  name: 'Login',
  // components: { LangSelect },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('Please enter the correct user name'))
      } else {
        callback()
      }
    }
    // const validatePassword = (rule, value, callback) => {
    //   if (value.length) {
    //     callback(new Error('The password can not be less than 6 digits'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        deviceId: '',
        validCode: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, message: 'Please enter password', trigger: 'blur' }],
        validCode: [{ required: true, message: 'Please enter verification code', trigger: 'blur' }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    this.getCodeUrl()
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      })
    },
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        if (shiftKey && (key >= 'a' && key <= 'z') || !shiftKey && (key >= 'A' && key <= 'Z')) {
          this.capsTooltip = true
        } else {
          this.capsTooltip = false
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false
      }
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false
            })
            .catch(() => {
              this.getCodeUrl()
              this.loading = false
            })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    getCodeUrl() {
      const deviceId = createUniqueString()
      this.codeUrl = `${process.env.VUE_APP_RIGHTS_API}auth/validata/code/${deviceId}`
      this.loginForm.deviceId = deviceId
    }
  }
}
</script>

<style  lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#fff;
$light_gray:#000;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;

    &::first-line {
      color: $light_gray;
    }
  }
}

/* reset element-ui css */
.login-container {
  position: relative;
  min-width: 900px;
  min-height: 500px ;
  .el-input {
    display: inline-block;
    height: 30px;
    width: 85%;

  /deep/input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 40px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }

  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #fff;
    border-radius: 5px;
    color: #454545;
    margin-bottom: 30px;
  }
}

.flex /deep/.el-form-item__content {
  display: flex;
  line-height: 28px;
}
</style>

<style  lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;
.code-verify {
  display: flex;
  justify-content: space-between;
  img {
    max-height: 42px;
  }
}
// .login-container  {
//   position: fixed;
//   background: url(http://subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/footer_lodyas.png);
//   animation: 200s scroll infinite linear;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }
#content{
    background-color: transparent;
    background-image:  url(../../assets/img/login.jpg);
    height:100%;
    background-size:cover;
}
.login-container {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #434343;
  background-image:linear-gradient(#434343, #282828);
//   animation: 200s scroll infinite linear;
}

@-webkit-keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}

@-moz-keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}

@-o-keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}

@-ms-keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}

@keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}
.login-container {

  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 35px;
    /* margin: 0 auto; */
    /* overflow: hidden; */
    /* border-radius: 10px; */
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translateX(-50%);
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
.lang-set {
  position: absolute;
  top: 50px;
  right: 10px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.login-footer {
  position: absolute;
  bottom: 20px;
  line-height: 18px;
  left: 50%;
  transform: translateX(-50%);
  p {
    text-align: center;
    color: #fff;

  }
}
</style>
